import {updateDates} from '../../../commons/actions/dates'
import {LOAD_CALENDAR_EVENTS, LOAD_EVENTS} from '../actions/events'

export const datesMiddleware = store => next => action => {
  switch (action.type) {
    case LOAD_CALENDAR_EVENTS.SUCCESS:
    case LOAD_EVENTS.SUCCESS: {
      store.dispatch(updateDates(action.payload.dates))
      break
    }
    default:
      break
  }
  next(action)
}
