import {TAB} from '../../../commons/constants/navigation'
import {DesignSection, TextSection} from '../../../commons/enums'
import {isEditor} from '../../../commons/selectors/environment'
import {
  getCalendarEvents,
  getMonthOfDate,
  getNextMonth,
  getPreviousMonth,
  getToday,
  isCalendarPageLoading,
  isDayInCurrentMonth,
} from '../selectors/calendar-layout'
import {CalendarLoading, GetState} from '../types/state'
import {loadCalendarEvents} from './events'

export const OPEN_MONTHLY_CALENDAR_POPUP = 'OPEN_MONTHLY_CALENDAR_POPUP'
export const CLOSE_MONTHLY_CALENDAR_POPUP = 'CLOSE_MONTHLY_CALENDAR_POPUP'

export const OPEN_MONTHLY_CALENDAR_EVENT = 'OPEN_MONTHLY_CALENDAR_EVENT'
export const CLOSE_MONTHLY_CALENDAR_EVENT = 'CLOSE_MONTHLY_CALENDAR_EVENT'

export const SET_CALENDAR_REFERENCE_DATE = 'SET_CALENDAR_REFERENCE_DATE'

export const addCalendarMonth = () => async (dispatch, getState: GetState) => {
  const referenceDate = getNextMonth(getState())
  await dispatch(loadCalendar(referenceDate, CalendarLoading.NEXT))
}

export const subCalendarMonth = () => async (dispatch, getState: GetState) => {
  const referenceDate = getPreviousMonth(getState())
  await dispatch(loadCalendar(referenceDate, CalendarLoading.PREVIOUS))
}

export const resetCalendar = (fullReset?: boolean) => async (dispatch, getState: GetState) => {
  const state = getState()

  let referenceDate
  if (!isEditor(state)) {
    const today = getToday(state)
    const startOfMonth = getMonthOfDate(state, today)
    referenceDate = startOfMonth ?? today
  }

  await dispatch(loadCalendar(referenceDate, CalendarLoading.DEFAULT, fullReset))
}

export const loadCalendar = (referenceDate?: string, origin?: CalendarLoading, reset?: boolean) => async (
  dispatch,
  getState: GetState,
) => {
  const state = getState()
  if (isCalendarPageLoading(state.calendarLayout)) {
    return
  }

  const response = await dispatch(loadCalendarEvents(referenceDate, origin, reset))
  if (!response) {
    await dispatch({
      type: SET_CALENDAR_REFERENCE_DATE,
      payload: getMonthOfDate(state, referenceDate) ?? referenceDate,
    })
  }
}

export const openMonthlyCalendarPopup = (day: string) => ({
  type: OPEN_MONTHLY_CALENDAR_POPUP,
  payload: {
    day,
  },
})

export const closeMonthlyCalendarPopup = () => ({type: CLOSE_MONTHLY_CALENDAR_POPUP})

export const openMonthlyCalendarEvent = (eventId: string) => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const events = getCalendarEvents(state)
  const day = Object.keys(events).find(key => events[key].includes(eventId))

  dispatch({
    type: OPEN_MONTHLY_CALENDAR_EVENT,
    payload: {
      eventId,
      day,
    },
  })
}

export const closeMonthlyCalendarEvent = () => ({
  type: CLOSE_MONTHLY_CALENDAR_EVENT,
})

export const openAnyEventDetails = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const events = getCalendarEvents(state)

  const day = Object.keys(events).find(dayWithEvents => isDayInCurrentMonth(state, dayWithEvents))

  if (day) {
    const eventId = events[day][0]
    dispatch(openMonthlyCalendarEvent(eventId))
  }
}

export const openAnyEventList = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const events = getCalendarEvents(state)

  const dayWithMoreThanOneEvent = Object.keys(events).find(
    dayWithEvents => isDayInCurrentMonth(state, dayWithEvents) && events[dayWithEvents].length > 1,
  )
  const dayWithOneEvent = Object.keys(events).find(
    dayWithEvents => isDayInCurrentMonth(state, dayWithEvents) && events[dayWithEvents].length === 1,
  )

  if (dayWithMoreThanOneEvent) {
    dispatch(openMonthlyCalendarPopup(dayWithMoreThanOneEvent))
  } else if (dayWithOneEvent) {
    dispatch(openMonthlyCalendarPopup(dayWithOneEvent))
  }
}

export const calendarSettingsTabChanged = (tab: TAB) => {
  if (tab === TAB.DISPLAY) {
    return openAnyEventDetails()
  } else {
    return closeMonthlyCalendarPopup()
  }
}

export const calendarSettingsSectionChanged = (id: DesignSection) => {
  if ([DesignSection.EVENT_DETAILS_POPUP, DesignSection.BUTTONS, DesignSection.RIBBONS].includes(id)) {
    return openAnyEventDetails()
  } else if (id === DesignSection.EVENT_LIST_POPUP) {
    return openAnyEventList()
  } else {
    return closeMonthlyCalendarPopup()
  }
}

export const calendarTextsSectionChanged = (id: TextSection) => {
  if ([TextSection.RSVP_CLOSED, TextSection.RSVP, TextSection.RIBBON].includes(id)) {
    return openAnyEventDetails()
  } else {
    return closeMonthlyCalendarPopup()
  }
}
