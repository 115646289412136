import {callAPI, createActions} from '../../../commons/actions/data-action-helper'
import {getFullLocale, isEditor, isMobile} from '../../../commons/selectors/environment'
import {isDemo, isTemplate} from '../../../commons/selectors/instance'
import {isCalendarLayout} from '../../../commons/selectors/settings'
import {isCalendarPageLoaded} from '../selectors/calendar-layout'
import {getRealEventsCount} from '../selectors/events'
import {getRecurringFilter} from '../selectors/settings'
import {CalendarLoading, GetState} from '../types/state'
import {resetCalendar} from './calendar-layout'

export const LOAD_EVENTS = createActions('LOAD_EVENTS')
export const LOAD_CALENDAR_EVENTS = createActions('LOAD_CALENDAR_EVENTS')

export const reloadEvents = () => async (dispatch, getState: GetState) => {
  const state = getState()
  if (isCalendarLayout(state.component)) {
    return dispatch(resetCalendar(true))
  } else {
    return dispatch(loadEventsPage(0))
  }
}

export const loadEventsPage = (offset?: number) => async (dispatch, getState: GetState) => {
  const state = getState()
  const {membersEnabled, paidPlansEnabled} = state
  offset = offset ?? getRealEventsCount(state)
  const categoryId = state.component.byCategoryId?.categoryId
  const filter = categoryId ? state.component.byCategoryId.statusFilter : state.component.byStatus?.filter
  const byEventId = !!state.component.byEventId
  const recurringFilter = getRecurringFilter(state)
  return dispatch(
    callAPI(LOAD_EVENTS, {
      offset,
      filter,
      byEventId,
      members: membersEnabled,
      paidPlans: paidPlansEnabled,
      locale: getFullLocale(state),
      categoryId,
      recurringFilter,
    }),
  )
}

export const loadCalendarEvents = (referenceDate?: string, origin?: CalendarLoading, fullReset?: boolean) => async (
  dispatch,
  getState: GetState,
) => {
  const state = getState()

  if (!fullReset && referenceDate && isCalendarPageLoaded(state.calendarLayout, referenceDate)) {
    return
  }

  const {membersEnabled, paidPlansEnabled} = state
  const categoryId = state.component.byCategoryId?.categoryId
  const filter = categoryId ? state.component.byCategoryId.statusFilter : state.component.byStatus?.filter
  const byEventId = !!state.component.byEventId
  const showcase = isDemo(state) || isTemplate(state) || isEditor(state) || isMobile(state)
  return dispatch(
    callAPI(
      LOAD_CALENDAR_EVENTS,
      {
        referenceDate,
        filter,
        byEventId,
        members: membersEnabled,
        paidPlans: paidPlansEnabled,
        locale: getFullLocale(state),
        categoryId,
        showcase,
      },
      origin,
      fullReset,
    ),
  )
}
