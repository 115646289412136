import {AnyAction} from 'redux'
import {RESIZE, RESIZED} from '../actions/resize'

export const needsResize = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case RESIZE:
      return true
    case RESIZED:
      return false
    default:
      return state
  }
}
